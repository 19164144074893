<template>
  <div class="peopleBox">
    <div class="input_btn">
      <el-input placeholder="请输入需要查询的点位名称" v-model.trim="nameVal">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
      <div>
        <el-button type="primary" @click="showDownQscode">下载试用版二维码</el-button>
        <el-button type="primary" @click="dialogTableVisibleShow">添加分组</el-button>
        <el-button type="primary" @click="toManage">巡检项目管理</el-button>
      </div>
    </div>
    <div class="listBox border_5">
      <div class="left">
        <el-tree expand-on-click-node :data="listDepartmentMember" node-key="id" :render-content="renderContent" accordion @node-click="nodeclick"></el-tree>
      </div>
      <div class="right">
        <div class="data" v-if="peopleInfo">
          <div class="data_left">
            <div class="first_item">
              <div class="item">
                <div class="title">点位名称</div>
                <div class="content" v-if="!editVar">
                  {{ editSitePar.name }}
                </div>
                <el-input v-model="editSitePar.name" placeholder="请输入点位名称" v-else style="width: 150px"></el-input>
              </div>
              <div></div>
            </div>
            <div class="item">
              <div class="title">点位位置</div>
              <div class="content">{{ editSitePar.address }}</div>
            </div>
            <div class="item">
              <div class="title">所属分组</div>
              <div class="content" v-if="!editVar">
                {{ editSitePar.group_name }}
              </div>
              <el-select v-model="editSitePar.group_id" placeholder="请选择" v-else style="width: 150px" @change="selectChange">
                <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="title">备注信息</div>
              <div class="content" v-if="!editVar">
                {{ editSitePar.remark || "未填写" }}
              </div>
              <el-input v-else type="textarea" :rows="3" placeholder="请输入内容" v-model="editSitePar.remark" style="width: 160px" maxlength="30" show-word-limit>
              </el-input>
            </div>
            <div class="item">
              <div class="btnBox">
                <el-button plain size="mini" v-if="editVar" @click="cancelEditPeopleData">取消</el-button>
                <el-button type="primary" plain size="mini" v-if="editVar" @click="clickQueryBtn">修改</el-button>
              </div>
              <span style="display: flex; align-items: center" v-if="!editVar">
                <el-button size="mini" type="text" circle icon="el-icon-edit" style="color: #66b1ff; font-size: 20px" v-if="
                    this.$store.state.current_project.role_id == 5 ||
                      this.$store.state.current_project.role_id == 8
                  " @click="editPeopleData"></el-button>
                <el-button size="mini" type="text" circle icon="el-icon-delete" style="color: #f56c6c; font-size: 20px" v-if="
                    this.$store.state.current_project.role_id == 5 ||
                      this.$store.state.current_project.role_id == 8
                  " @click="deleteLocation(editSitePar.id)"></el-button>
                <!-- @click="deletePeopleData" -->
              </span>
            </div>
          </div>
          <div class="data_right">
            <el-button type="primary" plain round size="mini" class="addDepartBtn" v-if="editVar" @click="qsCOdeVisibleShow">添加部门</el-button>

            <el-tree :data="treeDataArr" node-key="id">
              <!-- :expand-on-click-node="false" -->
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span v-if="!data.last">{{ node.label }}</span>
                <span v-if="editVar && node.level == 1">
                  <el-button type="text" size="mini" @click="appendSite(data, node)">
                    <!-- @click="() => appendSite(data, node)" -->
                    <i class="el-icon-remove" style="font-size: 18px"></i>
                  </el-button>
                </span>
                <span v-if="editVar && node.level == 2 && !data.last">
                  <el-button type="text" size="mini" @click="() => appendSite(data)">
                    <i class="el-icon-remove" style="font-size: 18px"></i>
                  </el-button>
                </span><span v-if="editVar && node.level == 2 && data.last">
                  <el-button type="text" size="mini" @click="() => addSite(data)">
                    <i class="el-icon-circle-plus" style="font-size: 18px"></i>
                  </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
        <div v-else class="checkInfo">请选择需要查看的点位信息</div>
        <div class="detail">
          <div class="firstCol">
            <div class="title">最近巡查记录</div>
            <div style="dateClas">
              日期
              <el-date-picker style="width: 200px; margin-right: 0px;margin-left:30px" v-model="data_stamp" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp"></el-date-picker>
            </div>
          </div>

          <div style="padding: 30px; height: 422px;overflow:auto" v-infinite-scroll="rollingLoad" infinite-scroll-delay="10000" :infinite-scroll-immediate="false" :infinite-scroll-disabled="true">
            <!-- @node-click="infoNode" -->
            <el-tree :data="patrolHistoryData" node-key="id" :expand-on-click-node="true" @node-expand="infoNode" accordion class="imgclass">
              <span class="custom-tree-node" slot-scope="{ node, data }" v-if="data.index === 1">
                <div class="flex_center_bew" style="width: 100%">
                  <span>{{ node.label }}</span>
                  <div class="flex_center_col">
                    <span v-if="data.patrol_stamp">{{
                      (data.patrol_stamp * 1000)
                        | dateformat("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                    <el-button type="warning" size="mini" v-if="data.skipped == 1" round style="margin-rigth: 20px; margin-left: 30px" plain>跳检</el-button>
                    <el-button size="mini" v-else-if="data.patrol_stamp == 0" round plain style="margin-rigth: 20px; margin-left: 30px">未检</el-button>
                    <el-button type="primary" size="mini" v-else-if="data.situation == 1" round style="margin-rigth: 20px; margin-left: 30px" plain>正常</el-button>
                    <el-button type="danger" size="mini" v-else round style="margin-rigth: 20px; margin-left: 30px" plain>异常</el-button>
                  </div>
                </div>
              </span>
              <span class="custom-tree-node" slot-scope="{ node, data }" v-else-if="data.index === 2">
                <span>{{ node.label }}</span>
                <span v-if="data.patrol_stamp" style="margin-right: -280px">{{
                  (data.patrol_stamp * 1000) | dateformat("YYYY-MM-DD HH:mm")
                }}</span>
                <el-button type="danger" size="mini" v-if="data.situation == 0" round style="margin-rigth: 20px" plain>异常</el-button>
                <el-button type="primary" size="mini" v-else round style="margin-rigth: 20px" plain>正常</el-button>
              </span>
              <span class="custom-tree-node" slot-scope="{ node, data }" v-else-if="(data.description && data.selected==1) || data.remark || data.files || data.value">
                <div>
                  <div v-if="data.value" style="color:#409eff">{{data.value}}</div>
                  <div v-if="data.remark">{{ data.remark }}</div>
                  <div v-if="data.description ">
                    <span class="dot"></span>
                    <span style="color:#409eff">{{ data.description }}</span>
                  </div>
                  <div v-if="data.files">
                    <div class="mediaClass">
                      <div v-for="item in data.files" :key="item.url">
                        <el-image v-if="item.type == 1" style="width: 100px; height: 100px;margin: 0 20px" :src="item.thumbnail_url" :preview-src-list="srcList" accordion></el-image>
                        <span @click="clickVideo(item.url)" style="
                            position: relative;
                            height: 100px;
                            width: 100px;
                            margin: 0 20px;
                            display:inline-block
                          " v-if="item.type == 2">
                          <i class="el-icon-video-play"></i>
                          <video :src="item.url" @click="clickVideoSlef" style="border:1px solid #c1c4cb"></video>
                        </span>
                        <el-image v-if="item.type == 4" style="width: 100px; height: 100px;margin: 0 20px" :src="item.url" :preview-src-list="srcList" accordion></el-image>
                      </div>
                    </div>
                    <div v-for="item in data.files" :key="item.url" style="margin: 20px" v-if="item.type == 3">
                      <audio :src="item.url" v-if="item.type == 3" controls="controls"></audio>
                    </div>
                  </div>
                </div>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="修改分组名称" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="点位分组旧名称" :label-width="formLabelWidth">
          <el-input v-model="DepartName" disabled></el-input>
        </el-form-item>
        <el-form-item label="点位分组新名称" :label-width="formLabelWidth">
          <el-input v-model.trim="editDepartName.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleFalse">取 消</el-button>
        <el-button type="primary" @click="queryEditDepartName">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 开启大图预览功能 -->
    <div class="popContainer" v-show="videoVisible" @click="closeVideoPage">
      <i class="el-icon-circle-close" @click="closeVideoPage"></i>
      <video class="video-js" controls preload="auto" data-setup="{}" ref="myVideo" style="width: 70vw; height: 80vh" :src="videoSrc"></video>
    </div>
    <!-- 弹出一个对话框 用来搜索人员 -->
    <el-dialog title="添加分组" :visible.sync="dialogTableVisible" style="margin: auto" width="30%">
      <el-input v-model.trim="newDepartInput" placeholder="请输入新分组名称"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAddDepart">取 消</el-button>
        <el-button type="primary" @click="queryAddDepart">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 邀请巡查选项弹窗 -->
    <el-dialog title="巡查选项" :visible.sync="searchPeople" width="30%">
      <el-tree :data="checkData" show-checkbox :accordion="true" node-key="id" ref="trees" highlight-current :props="defaultProps" @check="checkNode">
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="searchPeople = false">取 消</el-button>
        <el-button type="primary" @click="invitedUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加部门 -->
    <el-dialog title="添加部门" :visible.sync="qsCOdeVisible" width="30%">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <div style="margin: 15px 0"></div>
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="city in listDepartment" :label="city.name" :key="city.id">{{ city.name }}</el-checkbox>
      </el-checkbox-group>

      <span slot="footer" class="dialog-footer">
        <el-button @click="qsCOdeVisible = false">取消</el-button>
        <el-button type="primary" @click="queryCheckDepartment">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 扫描二维码下载 -->
  </div>
</template>
<script>
import { organization, statistic, patrol, user, site, envUrl } from '../../utils/api';
import { isTelAvailable } from '../../utils/verification';
import QRCode from 'qrcodejs2';
import qs from 'qs';
export default {
  name:'location',
  data() {
    return {
      //  获取部门信息
      listDepartmentMember: [],
      // 用于存储信息列表
      selectData: [],
      // 原部门名称
      DepartName: '',
      // 修改部门名称
      editDepartName: {
        // department_id: "",
        group_id: '',
        name: ''
      },
      // 用于控制显示dialog对话框
      dialogFormVisible: false,
      // 用于控制显示新建部门对话框
      creatVisible: false,
      // 新创建部门的名称
      creatDepartName: '',
      formLabelWidth: '120px',
      // 右侧面板展示个人信息
      peopleInfo: '',
      //获取日报所需的时间参数
      dayReport: {
        date_stamp: '',
        user_id: ''
      },
      data_stamp: '',
      diffValue: '',
      //判断是否给予加载
      rolling: true,
      // 获取巡查记录
      patrolHistory: {
        data_stamp: '',
        page: 0,
        page_size: 10,
        type: 0,
        site_id: ''
      },
      patrolHistoryData: [],
      srcList: [],
      isShow: false,
      // 视频弹窗
      videoVisible: false,
      // 视频地址
      videoSrc: '',
      // 监视左上角input框
      nameVal: '',
      todayData: {},
      yesterdayData: {},
      // 更新其管理角色以及部门
      editPeopleInfo: {
        department_id: '',
        role_id: '',
        user_id: ''
      },
      roldArr: [
        { label: '项目所有者', value: 5 },
        { label: '项目管理员', value: 8 },
        { label: '部门管理者', value: 6 },
        { label: '普通成员', value: 7 }
      ],
      editVar: false,
      JoinListPar: {
        page: 1,
        page_size: 10
      },
      memberList: [],
      memberListTotal: '',
      dialogTableVisible: false,
      searchPeople: false,
      keyword: '', // 搜索的手机号
      searchPeopleData: {},
      qsCOdeVisible: false,
      editSitePar: {
        id: '',
        name: '',
        group_id: '',
        address: '',
        longitude: '',
        latitude: '',
        remark: '',
        label_serial_number: '',
        qrcode_serial_number: '',
        bluetooth_serial_number: ''
      },
      activeNames: ['1', '2', '3', '4'],
      defaultProps: {
        children: 'inspections',
        label: 'name'
      },
      // 获取选项列表
      checkData: [],
      // 默认选择的节点
      defaultList: [],
      // 设置节点的id
      addSitePar: {
        arr: []
      },
      isIndeterminate: false,
      checkedCities: [],
      checkAll: false,
      listDepartment: [],
      newDepartInput: '',
      showDownQscodeShow:false// 试用版二维码显示
    };
  },

  components: {},

  computed: {},
  created() {
    this.data_stamp = new Date(new Date().toLocaleDateString()).getTime();
    this.getSetList();
    this.getListCategoryInspection();
    this.getListDepartment();
  },
  mounted() {},
  methods: {
    // 获取点位列表
    async getSetList() {
      const res = await this.$http.get(site.listGroupSite);
      let selectData = [];
      if (!res.data) {
        return false;
      }
      res.data.forEach(item => {
        let obj = {};
        obj.label = item.name;
        obj.value = item.id;
        selectData.push(obj);
        item.label = item.name;
        item.children = item.sites;
        item.children.forEach(citem => {
          // citem.patrol_stamp = item.patrol_stamp;
          // citem.id = citem.user_id;
          citem.label = citem.name;
          citem.department_name = item.label;
        });
      });
      this.listDepartmentMember = res.data;
      // 克隆一份是为了后面做模糊收缩
      let cloneListDepartmentMember = JSON.parse(
        JSON.stringify(this.listDepartmentMember)
      );
      this.cloneListDepartmentMember = cloneListDepartmentMember;
      // 用来修改分组
      this.selectData = selectData;
    },
    append(data, node) {
      // e.stopPropagation()
      window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
      if (+data.id === 0) {
        return this.$message.error('该分组不可修改名称');
      }
      this.DepartName = data.label;
      // this.editDepartName.department_id = data.id;
      this.editDepartName.group_id = data.id;
      this.dialogFormVisible = true;
    },
    //移除点位
    appendSite(data) {
      if (data.children) {
        this.$confirm(
          '此操作将删除有关该点位的所有巡检项目, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            // 别问我为什么用过滤不用splice 用为splice不起效果
            this.treeDataArr = this.treeDataArr.filter(item => {
              return item.id !== data.id;
            });
            this.$forceUpdate();
          })
          .catch(() => {});
      } else {
        this.$confirm('此操作将删除有关该巡检项目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 别问我为什么用过滤不用splice 用为splice不起效果
            this.treeDataArr.forEach(item => {
              item.children = item.children.filter(cItem => {
                return cItem.id !== data.id;
              });
            });
            this.$forceUpdate();
          })
          .catch(() => {});
      }
    },
    // 添加点位设置
    // 进行过滤节点操作
    async addSite(data) {
      this.addSitePar.pid = data.parId;
      let defaultList = [];
      let defaultArr = [];
      this.checkData = JSON.parse(JSON.stringify(this.cloneCheckData));
      this.treeDataArr.forEach(item => {
        if (item.id !== data.parId) {
          return;
        }
        item.children.forEach(citem => {
          if (citem.id) {
            this.checkData.forEach(chItem => {
              chItem.inspections = chItem.inspections.filter(cchItem => {
                return cchItem.id != citem.id;
              });
            });
          }
        });
      });
      if (this.$refs.trees) {
        this.$refs.trees.setCheckedKeys([]);
      }
      await this.$forceUpdate();
      this.searchPeople = true;
    },
    // 删除点位
    remove(node, data) {
      // 阻止事件冒泡
      window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
      let that = this;
      if (+data.id === 0) {
        return this.$message.error('该分组不可删除');
      }
      this.DepartName = data.label;
      this.$confirm('此操作将永久删除该分组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'queryBtn',
        type: 'warning'
      })
        .then(async () => {
          const res = await that.$http.get(
            site.deleteGroup + '?group_id=' + data.id
          );
          if (res.code === 0) {
            // this.listDepartmentMember.forEach((item, index, arr) => {
            //   if (item.id == data.id) {
            //     arr.splice(index, 1);
            //   }
            // });
            await this.getSetList();
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 渲染左侧树状数据
    renderContent(h, { node, data, store }) {
      if (data.children) {
        if (
          this.$store.state.current_project.role_id == 5 ||
          this.$store.state.current_project.role_id == 8
        ) {
          return (
            <div class="custom-tree-node">
              <div class="custom-tree-node-title">
                {node.label} ({data.children.length})
              </div>
              <div>
                <span style="display:flex;align-items: center;">
                  <el-button
                    size="mini"
                    type="text"
                    circle
                    icon="el-icon-edit"
                    style="color:#66b1ff;font-size:20px"
                    on-click={() => this.append(data)}
                  ></el-button>
                  <el-button
                    size="mini"
                    type="text"
                    circle
                    icon="el-icon-delete"
                    style="color:#f56c6c;font-size:20px"
                    on-click={() => this.remove(node, data)}
                  ></el-button>
                </span>
              </div>
            </div>
          );
        } else
          return (
            <div class="custom-tree-node">
              <div class="custom-tree-node-title">
                {node.label} ({data.children.length})
              </div>
            </div>
          );
      } else {
        if (data.avatar) {
          return (
            <div class="custom-tree-node">
              <div class="custom-tree-node-title">
                <span>{node.label}</span>
              </div>
            </div>
          );
        } else
          return (
            <div class="custom-tree-node active-node">
              <div class="custom-tree-node-title">
                <span>{node.label}</span>
              </div>
            </div>
          );
      }
    },
    dialogFormVisibleFalse() {
      this.editDepartName.name = '';
      this.dialogFormVisible = false;
    },
    // 确定发送添加部门请求
    async queryEditDepartName() {
      //  先判断部门名称是否为空
      if (!this.editDepartName.name) {
        return this.$message.error('请填写更改后的点位分组名称');
      }
      const res = await this.$http.post(site.editGroup, this.editDepartName);
      if (res.code === 0) {
        // 进行优化代码 更新并修改原始数据
        this.listDepartmentMember.forEach(item => {
          if (item.name == this.DepartName) {
            item.label = this.editDepartName.name;
            return false;
          }
        });
        this.dialogFormVisibleFalse();
      } else {
        this.$message.error('名称已存在');
      }
    },
    //  创建部门隐藏 并清空相对应的部门名称
    creatVisibleFalse() {
      this.creatVisible = false;
      this.creatDepartName = '';
    },
    // 点击创建新部门
    async queryCreatDepartName() {
      const res = await this.$http.post(organization.createDepartment, {
        name: this.creatDepartName
      });
      if (res.code === 0) {
        // 更新信息列表
        this.getSetList();
        // 情况并关闭弹窗
        this.creatVisibleFalse();
      }
    },
    // 点击事件
    async nodeclick(data, node, store) {
      if (this.editVar) {
        return false;
      }
      if (!data.children) {
        if(!this.patrolHistory.date_stamp){
          this.patrolHistory.date_stamp = this.data_stamp/1000
        }
        const res = await this.$http.get(`${site.siteInfo}?id=${data.id}`);
        this.peopleInfo = data;
        this.editSitePar = res.data;
        let treeDataArr = [];
        this.editSitePar.inspections.forEach(item => {
          let treeData = {};
          treeData.label = item.department_name;
          treeData.id = item.department_id;
          treeData.children = [];
          let chirenObj = {};
          chirenObj.label = item.name;
          chirenObj.id = item.id;
          chirenObj.parId = item.department_id;
          let isEist = treeDataArr.find(citem => {
            return citem.id === item.department_id;
          });
          if (isEist) {
            isEist.children.push(chirenObj);
          } else {
            treeData.children.push(chirenObj);
            treeDataArr.push(treeData);
          }
        });
        let defaultList = [];
        treeDataArr.forEach(item => {
          item.children.forEach(citem => {
            defaultList.push(citem.id);
          });
        });
        this.defaultList = defaultList;
        this.treeDataArr = treeDataArr;
        this.cloneTreeDataArr = JSON.parse(JSON.stringify(treeDataArr));
        // 然后深拷贝一份用于取消保存
        this.cloneEditSitePar = JSON.parse(JSON.stringify(this.editSitePar));
      }
    },
    // 获取日报
    async getDayReport() {
      let par = qs.stringify(this.dayReport);
      const res = await this.$http.get(statistic.memberDailyReport + '?' + par);
      let todayData = res.data.today;
      let yesterdayData = res.data.yesterday;
      this.todayData = todayData;
      this.yesterdayData = yesterdayData;
      function addPlus(today, yesterday) {
        let result = today - yesterday;
        if (result >= 0) {
          result = '+' + result;
        }
        return result;
      }
      let diffValue = {
        patrolNum: addPlus(todayData.patrol_count, yesterdayData.patrol_count),
        omitNum: addPlus(
          todayData.prescribed_count - todayData.prescribed_patrol_count,
          yesterdayData.prescribed_count - yesterdayData.prescribed_patrol_count
        ),
        completetaskcount_num: addPlus(
          todayData.complete_task_count,
          yesterdayData.complete_task_count
        ),
        patrol_count_num: addPlus(
          todayData.patrol_count,
          yesterdayData.patrol_count
        ),
        problem_count_num: addPlus(
          todayData.problem_count,
          yesterdayData.problem_count
        ),
        handled_problem_count_num: addPlus(
          todayData.handled_problem_count,
          yesterdayData.handled_problem_count
        )
      };
      this.diffValue = diffValue;
    },
    // 滚动加载数据
    async rollingLoad() {
      let that = this;
      if (!this.rolling) {
        return false;
      }
      // 这里用箭头函数的话就不用that了
      // 前几天写的就是一坨屎
      setTimeout(async function() {
        if (that.rolling) {
          that.rolling = false;
          if (!that.patrolHistory.site_id) {
            return (that.rolling = false);
          }
          that.patrolHistory.page++;
          let par = '?' + qs.stringify(that.patrolHistory);
          const res = await that.$http.get(patrol.siteHistory + par);
          res.data.forEach(item => {
            // 第一级
            item.label = item.patrol_user_name;
            item.children = item.inspection_logs;
            item.index = 1;
            item.children.forEach(citem => {
              // 第二级
              citem.index = 2;
              citem.label = citem.inspection_name;
              citem.children = citem.option_logs.filter(
                fitem => fitem.description && fitem.selected == 1
              );
              if (citem.signature) {
                citem.files.push({ type: 4, url: citem.signature });
              }
              if (citem.remark) {
                citem.children.push({ remark: citem.remark });
              }
              if (citem.inspection.type == 4) {
                citem.children.unshift({ value: citem.value });
              }
              if (citem.files.length > 0) {
                citem.children.push({ files: citem.files });
              }
            });
          });
          // 应该用total的 会准确一点
          that.patrolHistoryData = [...that.patrolHistoryData, ...res.data];
          if (that.patrolHistoryData.length == res.total) {
            that.rolling = false;
          } else {
            that.rolling = true;
          }
        }
      }, 1);
    },
    infoNode(data, node, self) {
      if (+node.level === 2) {
        let srcList = [];
        if (
          data.children[data.children.length - 1].files &&
          data.children[data.children.length - 1].files.length > 0
        ) {
          data.children[data.children.length - 1].files.forEach(item => {
            if (item.type === 1 || item.type === 4) {
              srcList.push(item.url);
            }
          });
          this.srcList = srcList;
        }
      }
    },
    clickVideoSlef() {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
    },
    clickVideo(url) {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
      this.$refs.myVideo.src = url;
      this.$refs.myVideo.play();
      this.videoVisible = true;
    },
    closeVideoPage() {
      this.$refs.myVideo.pause();
      this.videoVisible = false;
    },
    // 点击修改按钮修改人员信息
    editPeopleData() {
      this.editVar = true;
      this.treeDataArr.forEach(item => {
        item.children.push({ last: 'last', parId: item.id });
      });
    },
    // 点击取消按钮取消编辑人员信息并恢复数据利用深拷贝
    cancelEditPeopleData() {
      this.editSitePar = JSON.parse(JSON.stringify(this.cloneEditSitePar));
      this.treeDataArr = JSON.parse(JSON.stringify(this.cloneTreeDataArr));
      this.editVar = false;
    },
    // 点击修改按钮确定发送请求修改数据
    // 然后可以进行两部分的比较处理
    // 最后使用或运算符号
    // 没事多用点脑子 有事也用点脑子
    // 方法总比困难多
    async clickQueryBtn() {
      this.treeDataArr.forEach(item => {
        item.children = item.children.filter(citem => {
          return citem.id;
        });
      });
      this.treeDataArr = this.treeDataArr.filter(item => {
        return item.children.length > 0;
      });
      this.$forceUpdate();
      let treeEdit =
        JSON.stringify(this.treeDataArr) ===
        JSON.stringify(this.cloneTreeDataArr);
      let dataEdit =
        JSON.stringify(this.editSitePar) ===
        JSON.stringify(this.cloneEditSitePar);
      if (dataEdit && treeEdit) {
        return (this.editVar = false);
      }
      let editSiteParArr = [];
      this.treeDataArr.forEach(item => {
        item.children.forEach(citem => {
          editSiteParArr.push({
            inspection_id: citem.id,
            department_id: citem.parId
          });
        });
      });

      // 前端取消数量限制
      // if (editSiteParArr.length > 9) {
      //   return this.$message.error(
      //     `巡查部门或巡检项目过多，总数不得超过9个，当前为${editSiteParArr.length}个`
      //   );
      // }
      this.editSitePar.inspections = editSiteParArr;
      let cloneEditSitePar = JSON.parse(JSON.stringify(this.editSitePar));
      delete cloneEditSitePar.address;
      delete cloneEditSitePar.bluetooth_serial_number;
      delete cloneEditSitePar.group_name;
      delete cloneEditSitePar.label_serial_number;
      delete cloneEditSitePar.latitude;
      delete cloneEditSitePar.longitude;
      delete cloneEditSitePar.organization_id;
      delete cloneEditSitePar.qrcode_serial_number;
      delete cloneEditSitePar.state;
      const res = await this.$http.post(site.editSite, cloneEditSitePar);
      if (res.code === 0) {
        // this.editSitePar.department_name = this.group_name
        this.cloneEditSitePar = JSON.parse(JSON.stringify(this.editSitePar));
        this.cloneTreeDataArr = JSON.parse(JSON.stringify(this.treeDataArr));
        this.$forceUpdate();
        this.getSetList();
        return (this.editVar = false);
      }
    },
    // 删除部门成员
    async deleteMember() {
      const res = await this.$http.get(
        organization.deleteMember + '?' + this.peopleInfo.user_id
      );
      if (res === 0) {
        this.getSetList();
        this.peopleInfo = {};
      }
    },
    // 页码改变时操作
    async currentChange(page) {
      (this.JoinListPar.page = page), await this.getListJoin();
      this.$forceUpdate();
    },
    // 点击搜索按钮用户
    async searchPeopleBtn() {
      this.searchPeopleData = {};
      this.$forceUpdate();
      if (!isTelAvailable(this.keyword)) {
        return this.$message.error('请输入正确的手机号');
      }
      const res = await this.$http.get(
        organization.searchUser + '?keyword=' + this.keyword
      );
      if (res.code === 0 && res.data.length > 0) {
        this.searchPeopleData = res.data;
      } else {
        this.$message.error('人物不存在');
      }
    },
    // 点击确认按钮让巡查选项加入群聊
    invitedUser() {
      let children = [];
      this.treeDataArr.forEach(item => {
        if (item.id === this.addSitePar.pid) {
          this.addSitePar.arr.forEach(item => {
            children.push({
              id: item.id,
              label: item.name,
              parId: this.addSitePar.pid
            });
          });
          // children.push({ last: "last", parId: this.addSitePar.pid });
          item.children.splice(item.children.length - 1, 0, ...children);
        }
      });
      this.searchPeople = false;
    },
    // 加入项目
    joinProject() {
      this.qsCOdeVisible = true;
    },
    qrCode(url) {
      let qrcode = new QRCode('qrcode', {
        width: 150, //图像宽度
        height: 150, //图像高度
        colorDark: '#000000', //前景色
        colorLight: '#ffffff', //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
      qrcode.makeCode(url); //生成另一个新的二维码
    },
    // 同意用户加入项目
    async agreeApoint(id, isAgree = 1) {
      this.$confirm('此操作将同意用户加入项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$http.post(user.approveInvite, {
            mjid: id,
            pass: isAgree
          });
          if (res.code === 0) {
            this.$message.success('已同意');
            this.getListJoin();
          } else {
            this.$message.error('操作失败');
          }
        })
        .catch(() => {});
    },
    async rejectApoint(id) {
      this.$confirm('此操作将同意用户加入项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$http.post(user.approveInvite, {
            mjid: id,
            pass: isAgree
          });
          if (res.code === 0) {
            this.$message.success('已拒绝');
            this.getListJoin();
          } else {
            this.$message.error('失败');
          }
        })
        .catch(() => {});
    },
    dialogTableVisibleShow() {
      this.dialogTableVisible = true;
    },
    // 获取巡检项目列表
    async getListCategoryInspection() {
      const res = await this.$http.get(site.listCategoryInspection);
      this.checkData = res.data;
      this.cloneCheckData = JSON.parse(JSON.stringify(this.checkData));
    },
    // 多选框节点改变事件
    checkNode(checkedNodes, checkedKeys) {
      let arr = checkedKeys.checkedNodes.filter(item => {
        return !item.inspections;
      });
      this.addSitePar.arr = arr;
    },
    // 下拉框改变
    selectChange(e) {
      this.editSitePar.group_name = this.selectData.find(item => {
        return item.value == e;
      }).label;
      this.group_name = this.editSitePar.group_name;
    },
    // 获取部门列表
    async getListDepartment() {
      const res = await this.$http.get(organization.listDepartment);
      if (res.code === 0) {
        this.listDepartment = res.data;
        this.cloneListDepartment = JSON.parse(
          JSON.stringify(this.listDepartment)
        );
      }
    },
    qsCOdeVisibleShow() {
      this.listDepartment = JSON.parse(
        JSON.stringify(this.cloneListDepartment)
      );
      if (this.treeDataArr.length === this.listDepartment.length) {
        return this.$message.success('所有部门均在该点位内');
      }
      this.checkAll = false;
      this.checkedCities = [];
      // 用过滤操作
      this.treeDataArr.forEach(item => {
        this.listDepartment = this.listDepartment.filter(fitem => {
          return fitem.id !== item.id;
        });
      });
      this.qsCOdeVisible = true;
    },
    //处理全选
    handleCheckAllChange(val) {
      let arr = [];
      this.listDepartment.forEach(item => {
        arr.push(item.name);
      });
      this.checkedCities = val ? arr : [];
      this.isIndeterminate = false;
    },
    //处理部门选择
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.listDepartment.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.listDepartment.length;
    },
    // 点击确定按钮选择部门
    queryCheckDepartment() {
      if (this.checkedCities.length === 0) {
        return (this.qsCOdeVisible = false);
      }
      this.checkedCities.forEach(item => {
        let find = this.listDepartment.find(fitem => {
          return item === fitem.name;
        });
        this.treeDataArr.push({
          id: find.id,
          label: find.name,
          children: [{ last: 'last', parId: find.id }]
        });
      });
      // this.treeDataArr=treeDataArr
      // 我也不知道为什么要这样子 因为不这么写的话 dom不会更新
      this.treeDataArr = [...this.treeDataArr];
      // this.treeDataArr=[...this.treeDataArr]
      this.qsCOdeVisible = false;
    },
    // 取消添加分组
    cancelAddDepart() {
      (this.newDepartInput = ''), (this.dialogTableVisible = false);
    },
    //点击确定添加新分组
    async queryAddDepart() {
      if (!this.newDepartInput) {
        return this.$message.error('请输入分组名称');
      }
      const res = await this.$http.post(site.createGroup, {
        name: this.newDepartInput
      });
      if (res.code === 0) {
        this.getSetList();
        this.cancelAddDepart();
      }
    },
    //前往巡检项目管理
    toManage() {
      this.$router.push('manageProject');
    },
    async deleteLocation(id) {
      this.$confirm('此操作将永久删除该点位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$http.get(site.deleteSite+'?id='+id);
          if (res.code === 0) {
            this.$message.success('删除点位成功');
            await this.getSetList();
            this.peopleInfo = '';
            this.patrolHistoryData=[]
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    async showDownQscode(){
      const res = await this.$http.get(site.trialQrcodeZipLink)
      window.open(`${envUrl}${res.data.url}`)
    },
    // async qsCOdeVisibleOPen(){

    //   if (!this.$refs.qrcode.innerHTML) {
    //     const res = await this.$http.get(site.trialQrcodeFileLink)

    //     this.qrCode(
    //       // `https://bat.i-patrol.cn/navigate/?operate=invite&organization_id=${this.$store.state.current_project.organization_id}` //正式版
    //       `${envUrl}${res.data}` //测试版
    //     );
    //   }
    // }
  },
  watch: {
    // 监视时间戳
    data_stamp(value) {
      if (!this.editSitePar.id) {
        return false;
      }
      this.patrolHistory.date_stamp = value / 1000;
      this.patrolHistoryData = [];
      this.patrolHistory.page = 0;
      this.rolling = true;
      this.rollingLoad();
    },
    // 监视id变化 并且发送数据请求更新视图
    'editSitePar.id'(newvalue, oldValue) {
      this.patrolHistory.site_id = newvalue;
      this.patrolHistoryData = [];
      this.patrolHistory.page = 0;
      this.rolling = true;
      this.rollingLoad();
    },
    // 监视数据 并使用正则表达式 过滤数据
    nameVal(value) {
      var regStr = 'abc';
      var reg = new RegExp(value, 'g'); // 'g'代表全局
      let arr = JSON.parse(JSON.stringify(this.cloneListDepartmentMember));
      arr.forEach(item => {
        item.children = item.children.filter(citem => {
          return reg.test(citem.name);
        });
      });
      this.listDepartmentMember = arr;
    }
  }
};
</script>
<style lang="scss" scope>
.custom-tree-node {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-items: center;
  min-height: 50px !important;
  .custom-tree-node-title {
    text-align: left;
    line-height: 100px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
}
// .is-current {
//   font-weight: 500 !important;
//   color: #0486fe !important;
// }
.el-tree-node__content {
  height: 50px;
}
</style>
<style lang="scss" scoped>
.peopleBox {
  width: 1200px;
  .input_btn {
    display: flex;
    justify-content: space-between;
  }
  .el-input {
    width: 300px;
  }
  .listBox {
    display: flex;
    margin-top: 8px;
  }
  .left {
    width: 273px;
    margin-right: 7px;
    background-color: #fff;
    height: 785px;
    overflow: auto;
    border-radius: 5px;
  }
  .right {
    // background-color: #fff;
    flex: 1;
    // overflow-x: scroll;
    border-radius: 5px;

    .checkInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 920px;
      min-height: 296px;
      color: #909399;
      background-color: #ffffff;
      margin-bottom: 8px;
      border-radius: 5px;
    }
    .data {
      width: 920px;
      display: flex;
      background: #ffffff;
      margin-bottom: 8px;
      padding-bottom: 8px;
      box-sizing: border-box;
      border-radius: 5px;
      .data_left {
        margin: 8px 0 0px 8px;
        width: 300px;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding-left: 16px;
        height: 280px;
        .first_item {
          display: flex;
          justify-content: space-between;
        }
        .item {
          display: flex;
          align-items: flex-start;
          line-height: 30px;
          // width: 300px;
          margin-top: 5px;
          .btnBox {
            display: flex;
            justify-content: space-around;
            width: 300px;
            // margin-top: 20px;
          }
          .title {
            margin-right: 20px;
            white-space: nowrap;
          }
        }
      }
      .data_right {
        width: 594px;
        flex-wrap: wrap;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-left: 10px;
        margin-top: 8px;
        overflow: auto;
        height: 280px;
        padding-left: 20px;
        padding-right: 20px;

        .item {
          box-sizing: border-box;
          width: 30%;
          //  background-color: salmon;
          .title {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            .title_name {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 20px;
            }
            .title_content {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
            .color_red {
              color: #f04749;
            }
            .color_blue {
              color: #0486fe;
            }
          }
          .num {
            margin-top: 30px;
            text-align: left;
            font-size: 20px;
            font-weight: 700;
            margin-left: 10px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #333333;
            line-height: 28px;
          }
        }
        .border {
          width: 1px;
          background-color: #ccc;
          height: 80px;
        }
      }
    }
    .detail {
      background-color: #ffffff;
      overflow-y: auto;
      border-radius: 5px;
      .firstCol {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: $fontSizeMap-20;
          font-weight: 600;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #333333;
          line-height: 22px;
          margin-left: 12px;
        }
      }
    }
    .dateClas {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .mediaClass {
      display: flex;
      align-items: center;
      height: 100px;
      margin-top: 50px;
      .el-icon-video-play {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
        font-size: 50px;
        color: #000000;
      }
    }
  }
  .addDepartBtn {
    position: relative;
    left: 390px;
    top: 10px;
    margin-bottom: 20px;
  }
}
#qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-button--mini,
.el-button--small {
  margin-right: 20px;
}
.peopleBox /deep/ .el-dialog {
  width: 800px;
}
.peopleBox /deep/ .el-button + .el-button {
  margin-left: -10px !important;
}
.peopleBox /deep/.el-avatar {
  margin-right: 10px;
}
.peopleBox /deep/.el-button.el-button--primary {
  margin-left: 30px !important;
}
.peopleBox
  /deep/
  .el-button.el-button--primary
  + .el-button.el-button--primary {
  margin-left: 30px !important;
}
.peopleBox /deep/ .el-button.el-button--danger.el-button--mini.is-round,
.peopleBox /deep/ .el-button.el-button--primary.el-button--mini.is-round {
  margin-right: 20px;
}
.peopleBox /deep/ .el-tree-node__content {
  // min-height: 26px !important;
  height: auto;
  white-space: normal;
}

.peopleBox /deep/ .custom-tree-node .custom-tree-node-title {
  line-height: 0 !important;
}
video {
  width: 100px;
  height: 100px;
}
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  // color: grey;
  background-color: rgb(64, 158, 255);
  border-radius: 50%;
  margin-right: 10px;
}
div.popContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  .el-icon-circle-close {
    position: absolute;
    right: 15%;
    top: 10%;
    font-size: 50px;
    color: slategray;
  }
}
.el-collapse {
  border: none;
}
.el-collapse-item__wrap {
  border: none;
}
.el-icon-remove {
  color: #f56c6c;
}
.el-tree {
  border-radius: 5px;
}
</style>
<style lang="scss">
.imgclass .custom-tree-node .el-tree-node__content {
  display: none;
}
</style>
